<script src="../../../../../core/services/i18n.service.js"></script>
<template>
  <div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('pos_session.pos_session') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-header border-0 py-5" style="justify-content: flex-end;">
        <div v-if="data.status !== 2" class="card-toolbar">
          <!-- <router-link :to="`point-of-sales/point-of-sales`" target="_blank" class="btn btn-info font-weight-bolder font-size-sm mr-3">
              {{ $t('MENU.sales_screen') }}
          </router-link> -->
          <a v-if="$can('point_sales.list')" :href="'/point-of-sales/point-of-sales'"
             class="btn btn-info font-weight-bolder font-size-sm mr-3">
            {{ $t('MENU.sales_screen') }}
          </a>
          <!-- :href="'/point-of-sales/point-of-sales'" target="_blank" -->
          <!-- <router-link class="btn btn-danger font-weight-bolder font-size-sm">
              {{ $t('MENU.close_session') }}
          </router-link> -->
          <b-button v-if="$can('pos_session.close_session')" class="btn btn-danger font-weight-bolder font-size-sm"
                    @click="closeSession(id)">
            {{ $t('MENU.close_session') }}
          </b-button>
        </div>
      </div>

      <div class="card-body">
        <b-tabs content-class="mt-3" lazy>
          <b-tab :title="$t('activity_log.basic_information')" active>
            <div class="mt-5 d-flex justify-content-end">
              <a :href="`/print/pos/session-details/${id}/print`" v-if="$can('pos_session.printing_export_pdf')" target="_blank" class="btn btn-dark font-weight-bolder mr-1 ml-1">{{ $t('pos_session.print') }}</a>
              <a :href="`/print/pos/session-details/${id}/pdf`" v-if="$can('pos_session.printing_export_pdf')" target="_blank" class="btn btn-dark font-weight-bolder mr-1 ml-1">{{ $t('pos_session.pdf') }}</a>
<!--              <button v-if="$can('pos_session.printing_export_pdf')" class="btn btn-dark font-weight-bolder mr-1 ml-1"-->
<!--                      @click="printInvoice('print')">-->
<!--                {{ $t('pos_session.print') }}-->
<!--              </button>-->
<!--              <button v-if="$can('pos_session.printing_export_pdf')" class="btn btn-dark font-weight-bolder mr-1 ml-1"-->
<!--                      @click="printInvoice('pdf')">-->
<!--                {{ $t('pos_session.pdf') }}-->
<!--              </button>-->
            </div>
            <div class="mt-5">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('pos_session.shift') }}</th>
                  <th>{{ $t('pos_session.session_id') }}</th>
                  <th>{{ $t('pos_session.device') }}</th>
                  <th>{{ $t('pos_session.staff') }}</th>
                  <th>{{ $t('pos_session.closed_date') }}</th>
                  <th>{{ $t('pos_session.opened_date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ data.shift_name }}</td>
                  <td>{{ data.shift_name }} / {{ data.opened_date }} / {{ data.session_id }}</td>
                  <td>{{ data.device_name }}</td>
                  <td>{{ data.added_by }}</td>
                  <td>{{ data.closed_date }}</td>
                  <td>{{ data.opened_date }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-5">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>{{ $t('pos_session.total_invoices') }}</th>
                  <th>{{ $t('pos_session.total_refund') }}</th>
                  <th>{{ $t('pos_session.total_credit') }}</th>
                  <th>{{ $t('pos_session.total_cash_movements_in') }}</th>
                  <th>{{ $t('pos_session.total_cash_movements_out') }}</th>
                  <th>{{ $t('pos_session.treasury_name') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ data.total_invoices }}</td>
                  <td>{{ data.total_refund }}</td>
                  <td>{{ data.total_credit }}</td>
                  <td>{{ data.total_cash_movements_in }}</td>
                  <td>{{ data.total_cash_movements_out }}</td>
                  <td>{{ data.treasury.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-5">
              <b-card class="mb-1 mb-0" no-body>
                <b-card-header class="bg-FFB803 p-1" header-tag="header" role="tab">
                  <i :class="data.cash_category && data.cash_category.length > 0 ? 'flaticon2-down':'flaticon2-next'"
                     class="text-dark-50 ml-3"></i>
                  <b-button v-b-toggle.collapse-2 class="text-dark" variant="link "><h4 class="c-white-bold mb-0">
                    {{ $t('pos_session.cash_categories') }}</h4></b-button>
                </b-card-header>
                <b-collapse id="collapse-2"
                            role="tabpanel">
                  <table class="table table-bordered mb-0">
                    <thead>
                    <tr>
                      <th>{{ $t('pos_session.cash_category') }}</th>
                      <th>{{ $t('pos_session.cash_category_no') }}</th>
                      <th>{{ $t('pos_session.amount') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in data.cash_category" :key="index">
                      <td>{{ row.cash_category_name }}</td>
                      <td>{{ row.cash_category_no || '0' }}</td>
                      <td>{{ row.amount || '0'}}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-collapse>
              </b-card>
            </div>

          </b-tab>
          <b-tab :title="$t('pos_session.invoices')">
            <div v-show="invoice_count" class="mt-5">
              <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">

                <template slot="invoice_info" slot-scope="props">
                  <p>#{{ props.row.invoice_code }} - {{ props.row.invoice_date }}</p>
                  <p v-if="props.row.customer">{{ props.row.customer.name }} #{{ props.row.customer.customer_code }}</p>
                  <p v-if="props.row.customer">{{ props.row.customer.city_name }}</p>
                  <p v-if="props.row.customer">{{ props.row.customer.address_1 }}, {{
                      props.row.customer.postal_code
                    }}</p>
                  <p v-if="props.row.customer">{{ props.row.customer.country_name }}</p>
                </template>
                <template slot="amount" slot-scope="props">
                  <p>{{ props.row.payment_amount }} {{ props.row.currency_name }}</p>
                  <p>{{ props.row.payment_status }}</p>
                </template>
                <template slot="actions" slot-scope="props">
                  <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                    <router-link v-if="$can('pos_session.printing_export_pdf')"
                                 :to="`/print/sales/${props.row.id}/print`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('MENU.invoice_printing') }}
                    </router-link>
                    <router-link v-if="$can('pos_session.printing_export_pdf')" :to="`/print/sales/${props.row.id}/pdf`"
                                 class="dropdown-item"
                                 target="_blank">
                      {{ $t('export_invoice_pdf') }}
                    </router-link>
                    <router-link :to="`/pos/pos-session/invoice/view/${props.row.id}`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('view') }}
                    </router-link>
                  </b-dropdown>
                </template>
              </v-server-table>
            </div>
            <p v-show="!invoice_count" class="mt-10 text-center">{{ $t('no_invoices_found') }}</p>
          </b-tab>
          <b-tab :title="$t('activity_log.activity_log')">
            <div class="mt-5">
              <activity-log :id="id" :inner-key="key"></activity-log>
            </div>

          </b-tab>
          <b-tab :active="current_tab == 'cash_movements' ||current_tab == 'cash_movement_out'"
                 :title="$t('pos_session.cash_movements') + ` (${cash_movement_count?cash_movement_count:'0'})`">
            <div v-if="data.status !== 2" class="mt-5">
              <div class="d-flex justify-content-end">
                <button v-if="$can('cash_movement.add_cash_in')" class="btn btn-success font-weight-bolder mr-1 ml-1"
                        @click="showModal(1)">
                  {{ $t('pos_session.add_cash_in') }}
                </button>
                <button v-if="$can('cash_movement.add_cash_out')" class="btn btn-danger font-weight-bolder mr-1 ml-1"
                        @click="showModal(2)">
                  {{ $t('pos_session.take_cash_out') }}
                </button>
              </div>
            </div>

            <div v-show="cash_movement_count" class="mt-5">
              <v-server-table ref="tableCashMovement" :class="'dataTable table-row-dashed'" :columns="columns2"
                              :options="options2">

                <template slot="date_time" slot-scope="props">
                  <p>{{ props.row.id }} - {{ props.row.transaction_date }}</p>

                  <div class="d-flex justify-content-center">
                    <p>
                      <i v-if="props.row.payment_type == 1" class="far fa-arrow-alt-circle-down text-success"
                         style="font-size: 35px;"></i>
                      <i v-else-if="props.row.payment_type == 2" class="far fa-arrow-alt-circle-up text-danger"
                         style="font-size: 35px;"></i>
                    </p>
                    <p class="ml-2 mr-2 pt-2">{{ props.row.added_by }}</p>
                  </div>
                </template>
                <template slot="amount" slot-scope="props">
                  <p>{{ props.row.cash_movement_amount }} {{ props.row.currency_name }}</p>
                  <p>{{ props.row.staff_name }}</p>
                </template>
                <template slot="actions" slot-scope="props">
                  <v-icon v-if="$can('cash_movement.update')" class="mr-4 ml-4 text-info" color="blue darken-2" small
                          @click="editItem(props.row)">mdi-pencil
                  </v-icon>
                  <v-icon v-if="$can('cash_movement.delete')" class="text-danger" color="red darken-2" small
                          @click="deleteItem(props.row)">mdi-delete
                  </v-icon>

                  <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                    <router-link v-if="$can('cash_movement.printing_export_pdf')"
                                 :to="`/print/cash-movement/${props.row.id}/print`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('print') }}
                    </router-link>
                    <router-link v-if="$can('cash_movement.printing_export_pdf')"
                                 :to="`/print/cash-movement/${props.row.id}/pdf`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('export_pdf') }}
                    </router-link>
                  </b-dropdown>
                </template>
              </v-server-table>
            </div>
            <p v-show="!cash_movement_count" class="mt-10 text-center">{{ $t('no_transactions_found') }}</p>
          </b-tab>
          <b-tab v-if="$can('pos_refund.list')"
                 :title="$t('pos_session.refunds') + ` (${refunds_count?refunds_count:'0'})`">
            <div v-show="refunds_count" class="mt-5">
              <v-server-table ref="tableRefunds" :class="'dataTable table-row-dashed'" :columns="columns3"
                              :options="options3">

                <template slot="refund_info" slot-scope="props">
                  <div>
                    <div><span>#{{ props.row.session_id }} - </span><span>{{ props.row.create_date }}</span></div>
                    <div><span>{{ $t('pos_refund.pos_client') }}: </span><span>{{ props.row.customer_name }}</span>
                    </div>
                    <div><span>#{{ props.row.invoice_code }}</span></div>
                  </div>
                </template>
                <template slot="date_time" slot-scope="props">
                  <div>
                    <span>{{ $t('pos_refund.added_payment') }}</span>
                  </div>
                  <div>
                    <span>{{ props.row.created_date_time }}</span>
                  </div>
                </template>
                <template slot="amount_currency" slot-scope="props">
                  <div>
                    <span>{{ props.row.invoice_refund_total }}</span> <span>{{ props.row.currency_name }}</span>
                  </div>
                </template>
                <template slot="actions" slot-scope="props">
                  <v-btn v-if="$can('pos_refund.update')" :to="`/sales/sales_refund/edit/${props.row.id}`" color="pink"
                         icon>
                    <v-icon class="mr-2 text-info" small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-icon v-if="$can('pos_refund.delete')" class="text-danger" small
                          @click="deleteRefundItem(props.row)">mdi-delete
                  </v-icon>
                  <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                    <router-link v-if="$can('pos_refund.add_payment')"
                                 :to="`/sales/payment_sales_invoices/create/${props.row.id}/refund`"
                                 class="dropdown-item">
                      {{ $t('pos_refund.add_payment') }}
                    </router-link>
                    <b-dropdown-item-button v-if="$can('pos_refund.printing_export_pdf')"
                                            @click="printPosRefund('print', props.row.id)">{{
                        $t('MENU.refund_receipt')
                      }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="$can('pos_refund.printing_export_pdf')"
                                            @click="printPosRefund('pdf', props.row.id)">{{ $t('export_invoice_pdf') }}
                    </b-dropdown-item-button>
                    <router-link v-if="$can('pos_refund.view')" :to="`/pos/pos-session/refund/view/${props.row.id}`"
                                 class="dropdown-item"
                                 target="_blank">
                      {{ $t('pos_refund.view') }}
                    </router-link>
                  </b-dropdown>
                </template>
              </v-server-table>
            </div>
            <p v-show="!refunds_count" class="mt-10 text-center">{{ $t('no_refund_receipts_found') }}</p>
          </b-tab>
          <b-tab :title="$t('accounts_routing.credit_note') + ` (${credit_count?credit_count:'0'})`">
            <div v-show="credit_count" class="mt-5">
              <v-server-table ref="tableCredit" :class="'dataTable table-row-dashed'" :columns="columns5"
                              :options="options5">

                <template slot="refund_info" slot-scope="props">
                  <div>
                    <div><span>#{{ props.row.session_id }} - </span><span>{{ props.row.create_date }}</span></div>
                    <div><span>{{ $t('pos_refund.pos_client') }}: </span><span>{{ props.row.customer_name }}</span>
                    </div>
                    <div><span>#{{ props.row.invoice_code }}</span></div>
                  </div>
                </template>
                <template slot="date_time" slot-scope="props">
                  <div>
                    <span>{{ $t('pos_refund.added_payment') }}</span>
                  </div>
                  <div>
                    <span>{{ props.row.created_date_time }}</span>
                  </div>
                </template>
                <template slot="amount_currency" slot-scope="props">
                  <div>
                    <span>{{ props.row.invoice_refund_total }}</span> <span>{{ props.row.currency_name }}</span>
                  </div>
                </template>
                <template slot="actions" slot-scope="props">
                  <v-btn v-if="$can('pos_credit.update')" :to="`/sales/credits/edit/${props.row.id}`" color="pink"
                         icon>
                    <v-icon class="mr-2 text-info" small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-icon v-if="$can('pos_credit.delete')" class="text-danger" small
                          @click="deleteCreditItem(props.row)">mdi-delete
                  </v-icon>
                  <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                    <router-link v-if="$can('pos_credit.add_payment')"
                                 :to="`/sales/payment_sales_invoices/create/${props.row.id}/refund`"
                                 class="dropdown-item">
                      {{ $t('pos_credit.add_payment') }}
                    </router-link>
                    <b-dropdown-item-button v-if="$can('pos_credit.printing_export_pdf')"
                                            @click="printPosCredit('print', props.row.id)">
                      {{ $t('MENU.refund_receipt') }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="$can('pos_credit.printing_export_pdf')"
                                            @click="printPosCredit('pdf', props.row.id)">{{ $t('export_invoice_pdf') }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>
              </v-server-table>
            </div>
            <p v-show="!credit_count" class="mt-10 text-center">{{ $t('no_credit_found') }}</p>
          </b-tab>
          <b-tab :title="$t('pos_session.client_payment')">

            <div v-show="client_payment_count" class="mt-5">
              <v-server-table ref="tableClientPayment" :class="'dataTable table-row-dashed'" :columns="columns4"
                              :options="options4">

                <template slot="payment_info" slot-scope="props">
                  <div>
                    <p class="m-0">#{{ props.row.customer_id }} - {{ $t('pos_refund.pos_client') }}
                      {{ props.row.customer_fullname }} </p>
                    <p class="m-0">{{ $t('pos_session.invoice') }} #{{ props.row.type_id }}</p>
                    <p class="m-0">{{ $t('pos_refund.pos_shift') }} #{{
                        (props.row.invoice && props.row.invoice.pos_session) ? props.row.invoice.pos_session.shift_id : ''
                      }}</p>
                    <p class="m-0">{{ props.row.collected_user_name }}</p>
                    <p class="m-0">{{ props.row.payment_method_name }}</p>
                  </div>
                </template>
                <template slot="amount_currency" slot-scope="props">
                  <div>
                    <p>{{ props.row.amount }} {{ props.row.currency_code }}</p>
                    <p>{{ props.row.payment_status_name }}</p>
                  </div>
                </template>
                <template slot="actions" slot-scope="props">
                  <v-btn color="pink" icon title="View" @click="showClientPaymentDetails(props.row.id)">
                    <v-icon class="mr-2" small>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn v-if="$can('payment_sales_invoices.update')"
                         :to="`/sales/payment_sales_invoices/edit/${props.row.id}/${props.row.url_type}`" color="pink"
                         icon
                         title="Edit">
                    <v-icon class="mr-2 text-info" small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-icon v-if="$can('payment_sales_invoices.delete')" class="text-danger" small title="Delete"
                          @click="deleteClientPaymentItem(props.row)">mdi-delete
                  </v-icon>

                  <b-dropdown id="dropdown-offset" :text="$t('more_actions') " class="m-2" variant="outline-primary">
                    <router-link v-if="$can('pos_session.printing_export_pdf')"
                                 :to="`/print/sales/payment/${props.row.id}/print`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('pos_session.payment_receipt') }}
                    </router-link>
                    <router-link v-if="$can('pos_session.printing_export_pdf')"
                                 :to="`/print/sales/payment/${props.row.id}/pdf`" class="dropdown-item"
                                 target="_blank">
                      {{ $t('export_pdf') }}
                    </router-link>
                  </b-dropdown>
                </template>
              </v-server-table>
            </div>
            <p v-show="!client_payment_count" class="mt-10 text-center">{{ $t('no_payments_found') }}</p>
          </b-tab>
        </b-tabs>
      </div>

    </div>
    <!--end::customer-->
    <b-modal id="cashMovementModal" ref="cashMovementModal" :title="$t('pos_session.cash_movement')" hide-footer
             size="lg">
      <pos-session-cash-movement-form :current-id="inner_cash_movement_id"
                                      :current-session-id="id"
                                      :payment-type="payment_type"
                                      @hide-modal="hideModal()"
                                      @handling-data="getDataAfterCashMovement">
      </pos-session-cash-movement-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import PosSessionCashMovementForm from "@/view/content/forms/PosSessionCashMovementForm";

export default {
  name: "view-pos-session",
  components: {'activity-log': ActivityLog, 'pos-session-cash-movement-form': PosSessionCashMovementForm},
  data() {
    return {
      mainRoute: 'pos/pos-sessions',
      mainRouteInvoices: 'sales/sales_invoices',
      mainRouteCashMovement: 'pos/cash-movement',
      mainRouteRefunds: 'pos/pos-refunds',
      mainCreditNote: 'sales/credits',
      mainRouteSessionChangeStatus: 'pos/pos-session/change-status',
      mainRouteClientPayment: 'sales/payment_sales_invoice/payment_by_session',
      id: this.$route.params.id ? this.$route.params.id : null,
      key: 'PosSession',
      data: {
        status: 2
      },
      columns: ['invoice_info', 'create_date', 'amount', 'actions'],
      columns2: ['date_time', 'amount', 'actions'],
      columns3: ['refund_info', 'date_time', 'amount_currency', 'actions'],
      columns4: ['payment_info', 'amount_currency', 'actions'],
      columns5: ['refund_info', 'date_time', 'amount_currency', 'actions'],
      filters: {
        session_id: this.$route.params.id ? this.$route.params.id : null,
      },
      current_tab: this.$route.params.current_tab ? this.$route.params.current_tab : null,
      payment_type: null,
      inner_cash_movement_id: null,
      cash_movement_count: null,
      refunds_count: null,
      credit_count: null,
      invoice_count: null,
      client_payment_count: null,
      status: 2,
    };
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          invoice_info: that.$t('pos_session.invoice_info'),
          create_date: that.$t('pos_session.create_date'),
          amount: that.$t('pos_session.amount'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRouteInvoices, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          that.invoice_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
    options2: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          date_time: that.$t('pos_session.date_time'),
          amount: that.$t('pos_session.amount'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'id'},
        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRouteCashMovement, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          that.cash_movement_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
    options3: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          refund_info: that.$t('pos_refund.refund_info'),
          date_time: that.$t('pos_refund.date_time'),
          amount_currency: that.$t('pos_refund.amount_currency'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRouteRefunds}`, {..._params});

        },
        responseAdapter(resp) {
          that.refunds_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
    options4: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          payment_info: that.$t('pos_session.payment_info'),
          amount_currency: that.$t('pos_session.amount_currency'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'id'},
        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: 0,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRouteClientPayment, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          that.client_payment_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        },

      }

    },
    options5: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          refund_info: that.$t('pos_refund.refund_info'),
          date_time: that.$t('pos_refund.date_time'),
          amount_currency: that.$t('pos_refund.amount_currency'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainCreditNote}`, {..._params});

        },
        responseAdapter(resp) {
          that.credit_count = resp.data.data.total;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },

  methods: {
    closeSession(id) {
      this.$confirmAlert(this.$t('do_you_want_to_close_session'), this.actionClose, id);
    },
    actionClose(id) {
      ApiService.patch(this.mainRouteSessionChangeStatus + '/' + id, {
        status: this.status,
      }).then((response) => {
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data = response.data.data;
      });
    },
    printInvoice(action) {
      if (this.$route.params.id) {
        window.open("/print/pos-sessions/" + this.$route.params.id + '/' + action, "_blank");
      }
    },
    printPosRefund(action, id) {
      if (id) {
        window.open("/print/pos-sessions/pos-refund/" + id + '/' + action, "_blank");
      }
    },
    printPosCredit(action, id) {
      if (id) {
        window.open("/print/sales-credit/" + id + '/' + action, "_blank");
      }
    },
    showModal(payment_type = null) {
      this.payment_type = payment_type;
      this.$refs['cashMovementModal'].show()
    },
    hideModal() {
      this.$refs['cashMovementModal'].hide();
      this.payment_type = null;
      this.inner_cash_movement_id = null;
    },
    getDataAfterCashMovement() {
      this.payment_type = null;
      this.inner_cash_movement_id = null;
      this.getData();
      this.getFetchCashMovement();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRouteCashMovement + "/" + item.id).then((response) => {
        this.getFetchCashMovement();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    actionRefundDelete(item) {
      ApiService.delete(this.mainRouteRefunds + "/" + item.id).then((response) => {
        this.getFetchRefund();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    actionCreditDelete(item) {
      ApiService.delete(this.mainCreditNote + "/" + item.id).then((response) => {
        this.getCreditNote();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },

    deleteRefundItem(item) {
      this.$confirmAlert('', this.actionRefundDelete, item);
    },
    deleteCreditItem(item) {
      this.$confirmAlert('', this.actionCreditDelete, item);
    },
    getFetchCashMovement() {
      this.$refs.tableCashMovement.refresh();
    },
    getFetchRefund() {
      this.$refs.tableRefund.refresh();
    },
    getCreditNote() {
      this.$refs.tableCredit.refresh();
    },
    getFetchClientPayment() {
      this.$refs.tableClientPayment.refresh();
    },
    editItem(item) {
      this.inner_cash_movement_id = item.id;
      this.showModal();
    },

    actionClientPaymentDelete(item) {
      ApiService.delete(this.mainRouteClientPayment + "/" + item.id).then((response) => {
        this.getFetchClientPayment();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteClientPaymentItem(item) {
      this.$confirmAlert('', this.actionClientPaymentDelete, item);
    },
    showClientPaymentDetails(id) {
      this.$router.push({name: 'payment_sales_invoices.details', params: {id: id}});
    }
  },

  mounted() {
    // ApiService.get('sales/credits').then((r)=>{
    //   console.log(r)
    // })
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.pos_sessions"),
      route: '/pos/pos-session'
    }, {title: this.$t('view')}]);

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'cashMovementModal') {
        this.payment_type = null;
        this.inner_cash_movement_id = null;
      }
    });
    if (this.id) {
      let promise = this.getData();
      Promise.all([promise]).then(() => {
        if (this.current_tab == 'cash_movements') {
          this.showModal(1);
        } else if (this.current_tab == 'cash_movement_out') {
          this.showModal(2);
        }
      })
    }

  },
};
</script>
